import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import AccordionWrap from '../../../components/ui/accordion'
import SectionTitle from '../../../components/ui/section-title'
import PopupVideo from '../../../components/ui/popup-video/layout-one'
import { Container, Row, Col } from '../../../components/ui/wrapper'
import { SectionWrap } from './style'

const FaqSection = ({ accordionStyle }) => {
    const data = useStaticQuery(graphql`
      query faqQuery {
        globalYaml(name: {eq: "faq"}) {
            header
            items {
              answer
              question
            }
          }
      }
    
    `)
    let title = data.globalYaml.header;
    let faqs= data.globalYaml.items;
    console.log(data)
    return (
        <SectionWrap>
            <Container>
                <Row>
                    <Col lg={12}>
                        <SectionTitle
                            mb="44px"
                            title={title}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <AccordionWrap {...accordionStyle}>
                            <Accordion allowZeroExpanded preExpanded={[0]}>
                                {
                                    faqs.map((el, index) => {
                                        return (
                                            <AccordionItem id={el.id} key={index}>
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                        {el.question}
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel>
                                                    <p>{el.answer}</p>
                                                </AccordionItemPanel>
                                            </AccordionItem>
                                        )
                                    })
                                }
                            </Accordion>
                        </AccordionWrap>
                    </Col>
                    {/* <Col lg={6}>
                        <PopupVideo
                            image={image}
                            video_link={video_link}
                        />
                    </Col> */}
                </Row>
            </Container>
        </SectionWrap>
    )
}

FaqSection.defaultProps = {
    accordionStyle: {
        responsive: {
            medium: {
                mb: '50px'
            }
        }
    }
}

export default FaqSection
